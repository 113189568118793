import React, { useState, useRef } from "react";

const ImageUploader = () => {
  const [images, setImages] = useState([]);
  const [splitHeight, setSplitHeight] = useState(1000); // デフォルトの分割高さを1000に設定
  const canvasRef = useRef(null);

  const handleDrop = async (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const img = new Image();
        img.src = e.target.result;
        await waitForImageLoad(img);
        const resizedImage = await resizeImage(
          img,
          img.width / 2,
          img.height / 2
        );
        const parts = await splitImage(resizedImage, splitHeight);
        setImages(parts);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const waitForImageLoad = (image) => {
    return new Promise((resolve) => {
      if (image.complete) {
        resolve();
      } else {
        image.onload = () => resolve();
      }
    });
  };

  const resizeImage = (image, width, height) => {
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(image, 0, 0, width, height);
      const resizedImage = new Image();
      resizedImage.src = canvas.toDataURL();
      waitForImageLoad(resizedImage).then(() => resolve(resizedImage));
    });
  };

  const splitImage = (image, height) => {
    return new Promise((resolve) => {
      const parts = [];
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const width = image.width;
      const totalHeight = image.height;

      canvas.width = width;
      canvas.height = height;

      for (let y = 0; y < totalHeight; y += height) {
        ctx.clearRect(0, 0, width, height);
        ctx.drawImage(image, 0, y, width, height, 0, 0, width, height);
        const part = new Image();
        part.src = canvas.toDataURL();
        parts.push(waitForImageLoad(part).then(() => part));
      }

      Promise.all(parts).then((loadedParts) => resolve(loadedParts));
    });
  };

  const combineImages = async () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const width = images[0].width;
    const gap = 8;
    const totalWidth = images.length * width + gap * (images.length - 1);

    canvas.width = totalWidth;
    canvas.height = splitHeight;

    for (let i = 0; i < images.length; i++) {
      const img = images[i];
      await waitForImageLoad(img);
      ctx.drawImage(img, i * width + i * gap, 0, width, splitHeight);
    }

    const combinedImage = canvas.toDataURL();
    const link = document.createElement("a");
    link.href = combinedImage;
    link.download = "combined-image.png";
    link.click();
  };

  return (
    <div
      className="flex flex-col justify-center items-center h-screen overflow-auto p-4"
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      {images.length === 0 && (
        <div className="mb-4">
          <label htmlFor="splitHeight" className="mr-2">
            Split Height (px):
          </label>
          <input
            type="number"
            id="splitHeight"
            value={splitHeight}
            onChange={(e) => setSplitHeight(Number(e.target.value))}
            className="border rounded p-1"
          />
        </div>
      )}

      <div className="border-4 border-dashed border-gray-300 p-10 text-center flex overflow-auto gap-2 [background-position:0_0,10px_10px] [background-size:20px_20px] bg-[linear-gradient(45deg,#eee_25%,transparent_25%,transparent_75%,#eee_75%,#eee_100%),linear-gradient(45deg,#eee_25%,white_25%,white_75%,#eee_75%,#eee_100%)]">
        {images.length > 0 ? (
          images.map((img, index) => (
            <img
              key={index}
              src={img.src}
              alt={`Part ${index}`}
              className="max-w-none h-auto"
            />
          ))
        ) : (
          <p>Drag & Drop an image here</p>
        )}
      </div>
      {images.length > 0 && (
        <button
          onClick={combineImages}
          className="mt-4 bg-blue-500 text-white py-2 px-4 rounded"
        >
          Download Combined Image
        </button>
      )}
      <canvas ref={canvasRef} style={{ display: "none" }} />
    </div>
  );
};

export default ImageUploader;
